import React, { useEffect } from 'react'

import { Helmet } from 'react-helmet'
import Hero from '../components/Hero'
import '../../static/styles/integration.css'
import Seo from "components/Seo"

const Integrations = () => {
  return (
    <>
      <Seo
        title={`Integrations | QuickMail`}
        description="Send cold emails and schedule as many follow-ups as you want. No more time wasted tracking who responds and who doesn’t — QuickMail takes care of that for you"
        url="/integrations"
      >
        <style>
          {`
            svg.icon.integration-icon * {
              fill: #545ff5 !important;
            }
          `}
        </style>
        <script
          type="module"
          src="https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.esm.js"
        ></script>
        <link
          rel="stylesheet"
          href="https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.css"
        />
      </Seo>
      <Hero>
        <div
          style={{
            paddingTop: '5rem',
            paddingBottom: '7rem',
          }}
        >
          <h1 className="title">Integrations</h1>
          <p className="details">
            Easily Integrate QuickMail With Your Existing Workflow With Our
            Native Integrations
          </p>
        </div>
      </Hero>
      <div
        className="container padded p-relative blog-post"
        style={{ zIndex: '10', paddingTop: '3rem' }}
      >
        <div className="elementor-inner post-content">
          <div className="elementor-section-wrap">
            <section
              className="elementor-section elementor-top-section elementor-element elementor-element-dc7636e bg-primary-alt elementor-section-boxed elementor-section-height-default elementor-section-height-default"
              data-id="dc7636e"
              data-element_type="section"
            >
              <div className="container elementor-column-gap-default">
                <div className="row">
                  <div
                    className="col-12 elementor-top-column elementor-element elementor-element-d912eb9"
                    data-id="d912eb9"
                    data-element_type="column"
                  >
                    <div className="elementor-column-wrap elementor-element-populated">
                      <div className="elementor-widget-wrap">
                        <div
                          className="elementor-element elementor-element-578962a elementor-widget elementor-widget-text-editor"
                          data-id="578962a"
                          data-element_type="widget"
                          data-widget_type="text-editor.default"
                        >
                          <div className="elementor-widget-container">
                            <div className="elementor-text-editor elementor-clearfix">
                              <h2 className="mb-0">Email Service Providers</h2>
                              <h4>
                                Use Your Favorite Email Service Provider with
                                ease, directly in QuickMail.
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section
              className="elementor-section elementor-top-section elementor-element elementor-element-065e5ba bg-primary-alt elementor-section-boxed elementor-section-height-default elementor-section-height-default"
              data-id="065e5ba"
              data-element_type="section"
            >
              <div className="container elementor-column-gap-default">
                <div className="row wrapper">
                  <div className="card card-body">
                    <h4>Gmail/GSuite</h4>
                    <p>
                      Connect with Gmail or GSuite so that all emails come from
                      you. We use Gmail API for better deliverability.
                    </p>
                  </div>
                  <div className="card card-body">
                    <h4>Outlook/Exchange</h4>
                    <p>
                      Connect with your Outlook Business account so that emails
                      come from you.
                    </p>
                  </div>
                  <div className="card card-body">
                    <h4>Custom SMTP/IMAP</h4>
                    <p>Connect with any email account using SMTP and IMAP</p>
                  </div>
                  <div className="card card-body">
                    <h4>Amazon SES</h4>
                    <p>Send emails with Amazon SES</p>
                  </div>
                  <div className="card card-body">
                    <h4>PostMark</h4>
                    <p>Send emails with PostMark</p>
                  </div>
                  <div className="card card-body">
                    <h4>MailJet</h4>
                    <p>Send emails with MailJet</p>
                  </div>
                  <div className="card card-body">
                    <h4>MailGun</h4>
                    <p>Send emails with MailGun</p>
                  </div>
                  <div className="card card-body">
                    <h4>Zoho Email</h4>
                    <p>Send emails with Zoho Email</p>
                  </div>
                </div>
              </div>
            </section>
            <section
              className="elementor-section elementor-top-section elementor-element elementor-element-26b21a6 bg-primary-alt elementor-section-boxed elementor-section-height-default elementor-section-height-default"
              data-id="26b21a6"
              data-element_type="section"
            >
              <div className="container elementor-column-gap-default">
                <div className="row">
                  <div
                    className="col-12 elementor-top-column elementor-element elementor-element-b9fc40d"
                    data-id="b9fc40d"
                    data-element_type="column"
                  >
                    <div className="elementor-column-wrap elementor-element-populated">
                      <div className="elementor-widget-wrap">
                        <div
                          className="elementor-element elementor-element-5511a79 elementor-widget elementor-widget-text-editor"
                          data-id="5511a79"
                          data-element_type="widget"
                          data-widget_type="text-editor.default"
                        >
                          <div className="elementor-widget-container">
                            <div className="elementor-text-editor elementor-clearfix">
                              <h2 className="mb-0">Email Verification Tools</h2>
                              <h4>
                                Verify your list of contacts with ease, directly
                                in QuickMail.
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section
              className="elementor-section elementor-top-section elementor-element elementor-element-f6e063c bg-primary-alt elementor-section-boxed elementor-section-height-default elementor-section-height-default"
              data-id="f6e063c"
              data-element_type="section"
              data-settings='{"background_background":"classic"}'
            >
              <div className="container elementor-column-gap-default">
                <div className="row wrapper">
                  <div className="card card-body">
                    <h4>NeverBounce</h4>
                    <p>
                      Boost your email deliverability by automatically verifying
                      emails with NeverBounce before sending out emails.
                    </p>
                  </div>
                  <div className="card card-body">
                    <h4>BriteVerify</h4>
                    <p>
                      Boost your email deliverability by automatically verifying
                      emails with BriteVerify before sending out emails.
                    </p>
                  </div>
                  <div className="card card-body">
                    <h4>ZeroBounce</h4>
                    <p>
                      Boost your email deliverability by automatically verifying
                      emails with ZeroBounce before sending out emails.
                    </p>
                  </div>
                  <div className="card card-body">
                    <h4>Dropcontact</h4>
                    <p>
                      Boost your email deliverability by automatically verifying
                      emails with Dropcontact before sending out emails.
                    </p>
                  </div>
                  <div className="card card-body">
                    <h4>Hunter</h4>
                    <p>
                      Boost your email deliverability by automatically verifying
                      emails with Hunter before sending out emails.
                    </p>
                  </div>
                  <div className="card card-body">
                    <h4>Bouncer</h4>
                    <p>
                      Boost your email deliverability by automatically verifying
                      emails with Bouncer before sending out emails.
                    </p>
                  </div>
                </div>
              </div>
            </section>


            <section
              className="elementor-section elementor-top-section elementor-element elementor-element-5999a3e bg-primary-alt elementor-section-boxed elementor-section-height-default elementor-section-height-default"
              data-id="5999a3e"
              data-element_type="section"
            >
              <div className="container elementor-column-gap-default">
                <div className="row">
                  <div
                    className="col-12 elementor-top-column elementor-element elementor-element-9fcddf1"
                    data-id="9fcddf1"
                    data-element_type="column"
                  >
                    <div className="elementor-column-wrap elementor-element-populated">
                      <div className="elementor-widget-wrap">
                        <div
                          className="elementor-element elementor-element-30721be elementor-widget elementor-widget-text-editor"
                          data-id="30721be"
                          data-element_type="widget"
                          data-widget_type="text-editor.default"
                        >
                          <div className="elementor-widget-container">
                            <div className="elementor-text-editor elementor-clearfix">
                              <h2 className="mb-0">Email Infrastructure</h2>
                              <h4>
                                These tools provides Google, Microsoft or custom SMTP email addresses.
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section
              className="elementor-section elementor-top-section elementor-element elementor-element-e29e421 bg-primary-alt elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-invisible"
              data-id="e29e421"
              data-element_type="section"
              data-settings='{"background_background":"classic","animation":"none"}'
            >
              <div className="container elementor-column-gap-default">
                <div className="row wrapper">
                  <div className="card card-body">
                    <h4>Zapmail</h4>
                    <p>
                      Automated email infrastructure optimized for deliverability and super easy to set up
                    </p>
                  </div>
                </div>
              </div>
            </section>

            <section
              className="elementor-section elementor-top-section elementor-element elementor-element-ab25732 bg-primary-alt elementor-section-boxed elementor-section-height-default elementor-section-height-default"
              data-id="ab25732"
              data-element_type="section"
            >
              <div className="container elementor-column-gap-default">
                <div className="row">
                  <div
                    className="col-12 elementor-top-column elementor-element elementor-element-5fe4ebc"
                    data-id="5fe4ebc"
                    data-element_type="column"
                  >
                    <div className="elementor-column-wrap elementor-element-populated">
                      <div className="elementor-widget-wrap">
                        <div
                          className="elementor-element elementor-element-b345708 elementor-widget elementor-widget-text-editor"
                          data-id="b345708"
                          data-element_type="widget"
                          data-widget_type="text-editor.default"
                        >
                          <div className="elementor-widget-container">
                            <div className="elementor-text-editor elementor-clearfix">
                              <h2 className="mb-0">Import Prospects</h2>
                              <h4>
                                Don’t Rely On Sending Files Around Anymore… But
                                It’s Still Fine If You Do.
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section
              className="elementor-section elementor-top-section elementor-element elementor-element-0e6053d bg-primary-alt elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-invisible"
              data-id="0e6053d"
              data-element_type="section"
              data-settings='{"background_background":"classic","animation":"none"}'
            >
              <div className="container elementor-column-gap-default">
                <div className="row wrapper">
                  <div className="card card-body">
                    <h4>CSV Files</h4>
                    <p>Upload your File in CSV format for easy import.</p>
                  </div>
                  <div className="card card-body">
                    <h4>Google Drive</h4>
                    <p>
                      Upload your Google Drive contact sheets to QuickMail for
                      easy import.
                    </p>
                  </div>
                </div>
              </div>
            </section>

            <section
              className="elementor-section elementor-top-section elementor-element elementor-element-5999a3e bg-primary-alt elementor-section-boxed elementor-section-height-default elementor-section-height-default"
              data-id="5999a3e"
              data-element_type="section"
            >
              <div className="container elementor-column-gap-default">
                <div className="row">
                  <div
                    className="col-12 elementor-top-column elementor-element elementor-element-9fcddf1"
                    data-id="9fcddf1"
                    data-element_type="column"
                  >
                    <div className="elementor-column-wrap elementor-element-populated">
                      <div className="elementor-widget-wrap">
                        <div
                          className="elementor-element elementor-element-30721be elementor-widget elementor-widget-text-editor"
                          data-id="30721be"
                          data-element_type="widget"
                          data-widget_type="text-editor.default"
                        >
                          <div className="elementor-widget-container">
                            <div className="elementor-text-editor elementor-clearfix">
                              <h2 className="mb-0">Native CRM Integration</h2>
                              <h4>
                                2 way synchronization to your personal CRM.
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section
              className="elementor-section elementor-top-section elementor-element elementor-element-e29e421 bg-primary-alt elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-invisible"
              data-id="e29e421"
              data-element_type="section"
              data-settings='{"background_background":"classic","animation":"none"}'
            >
              <div className="container elementor-column-gap-default">
                <div className="row wrapper">
                  <div className="card card-body">
                    <h4>Hubspot</h4>
                    <p>
                      Integrate directly with Hubspot CRM and enjoy 2-way
                      synchronization.
                    </p>
                  </div>
                  <div className="card card-body">
                    <h4>Pipedrive</h4>
                    <p>
                      Integrate directly with Pipedrive CRM and enjoy 2-way
                      synchronization.
                    </p>
                  </div>
                </div>
              </div>
            </section>

            <section
              className="elementor-section elementor-top-section elementor-element elementor-element-5999a3e bg-primary-alt elementor-section-boxed elementor-section-height-default elementor-section-height-default"
              data-id="5999a3e"
              data-element_type="section"
            >
              <div className="container elementor-column-gap-default">
                <div className="row">
                  <div
                    className="col-12 elementor-top-column elementor-element elementor-element-9fcddf1"
                    data-id="9fcddf1"
                    data-element_type="column"
                  >
                    <div className="elementor-column-wrap elementor-element-populated">
                      <div className="elementor-widget-wrap">
                        <div
                          className="elementor-element elementor-element-30721be elementor-widget elementor-widget-text-editor"
                          data-id="30721be"
                          data-element_type="widget"
                          data-widget_type="text-editor.default"
                        >
                          <div className="elementor-widget-container">
                            <div className="elementor-text-editor elementor-clearfix">
                              <h2 className="mb-0">Workflow</h2>
                              <h4>
                                Leverage those tools while using QuickMail.
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section
              className="elementor-section elementor-top-section elementor-element elementor-element-e29e421 bg-primary-alt elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-invisible"
              data-id="e29e421"
              data-element_type="section"
              data-settings='{"background_background":"classic","animation":"none"}'
            >
              <div className="container elementor-column-gap-default">
                <div className="row wrapper">
                  <div className="card card-body">
                    <h4>Aircall</h4>
                    <p>
                      Integrate with a modern phone system for your Call steps.
                    </p>
                  </div>
                  <div className="card card-body">
                    <h4>Twilio</h4>
                    <p>
                      Send SMS to your prospects using Twilio phone numbers.
                    </p>
                  </div>
                  <div className="card card-body">
                    <h4>Slack</h4>
                    <p>
                      Send slack messages to your channels and stay informed.
                    </p>
                  </div>
                </div>
              </div>
            </section>
            <section
              className="elementor-section elementor-top-section elementor-element elementor-element-8a37788 bg-primary-alt elementor-section-boxed elementor-section-height-default elementor-section-height-default"
              data-id="8a37788"
              data-element_type="section"
            >
              <div className="container elementor-column-gap-default">
                <div className="row">
                  <div
                    className="col-12 elementor-top-column elementor-element elementor-element-2311c5c"
                    data-id="2311c5c"
                    data-element_type="column"
                  >
                    <div className="elementor-column-wrap elementor-element-populated">
                      <div className="elementor-widget-wrap">
                        <div
                          className="elementor-element elementor-element-98e8ec6 elementor-widget elementor-widget-text-editor"
                          data-id="98e8ec6"
                          data-element_type="widget"
                          data-widget_type="text-editor.default"
                        >
                          <div className="elementor-widget-container">
                            <div className="elementor-text-editor elementor-clearfix">
                              <h2 className="mb-0">Personalization</h2>
                              <h4>
                                Increase prospect engagement with
                                personalization.
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section
              className="elementor-section elementor-top-section elementor-element elementor-element-0bdcad8 bg-primary-alt elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-invisible"
              data-id="0bdcad8"
              data-element_type="section"
              data-settings='{"background_background":"classic","animation":"none"}'
            >
              <div className="container elementor-column-gap-default">
                <div className="row wrapper">
                  <div className="card card-body">
                    <h4>Hyperise</h4>
                    <p>
                      Insert customized emails directly in your campaigns on
                      QuickMail.
                    </p>
                  </div>
                </div>
              </div>
            </section>
            <section
              className="elementor-section elementor-top-section elementor-element elementor-element-5fab7cd bg-primary-alt elementor-section-boxed elementor-section-height-default elementor-section-height-default"
              data-id="5fab7cd"
              data-element_type="section"
            >
              <div className="container elementor-column-gap-default">
                <div className="row">
                  <div
                    className="col-12 elementor-top-column elementor-element elementor-element-bd660ce"
                    data-id="bd660ce"
                    data-element_type="column"
                  >
                    <div className="elementor-column-wrap elementor-element-populated">
                      <div className="elementor-widget-wrap">
                        <div
                          className="elementor-element elementor-element-5833ff7 elementor-widget elementor-widget-text-editor"
                          data-id="5833ff7"
                          data-element_type="widget"
                          data-widget_type="text-editor.default"
                        >
                          <div className="elementor-widget-container">
                            <div className="elementor-text-editor elementor-clearfix">
                              <h2 className="mb-0">Scheduling Apps</h2>
                              <h4>
                                Detect meetings booked automatically from within QuickMail.
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section
              className="elementor-section elementor-top-section elementor-element elementor-element-26cf284 bg-primary-alt elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-invisible"
              data-id="26cf284"
              data-element_type="section"
              data-settings='{"background_background":"classic","animation":"none"}'
            >
              <div className="container elementor-column-gap-default">
                <div className="row wrapper">
                  <div className="card card-body">
                    <h4>Calendly</h4>
                    <p>
                      Automatically stop follow-ups when a Calendly appointment
                      is scheduled.
                    </p>
                  </div>
                </div>
              </div>
            </section>
            <section
              className="elementor-section elementor-top-section elementor-element elementor-element-5fab7cd bg-primary-alt elementor-section-boxed elementor-section-height-default elementor-section-height-default"
              data-id="5fab7cd"
              data-element_type="section"
            >
              <div className="container elementor-column-gap-default">
                <div className="row">
                  <div
                    className="col-12 elementor-top-column elementor-element elementor-element-bd660ce"
                    data-id="bd660ce"
                    data-element_type="column"
                  >
                    <div className="elementor-column-wrap elementor-element-populated">
                      <div className="elementor-widget-wrap">
                        <div
                          className="elementor-element elementor-element-5833ff7 elementor-widget elementor-widget-text-editor"
                          data-id="5833ff7"
                          data-element_type="widget"
                          data-widget_type="text-editor.default"
                        >
                          <div className="elementor-widget-container">
                            <div className="elementor-text-editor elementor-clearfix">
                              <h2 className="mb-0">Monitoring Apps</h2>
                              <h4>
                                Monitor Your QuickMail Data.
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section
              className="elementor-section elementor-top-section elementor-element elementor-element-26cf284 bg-primary-alt elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-invisible"
              data-id="26cf284"
              data-element_type="section"
              data-settings='{"background_background":"classic","animation":"none"}'
            >
              <div className="container elementor-column-gap-default">
                <div className="row wrapper">
                  <div className="card card-body">
                    <h4>MailFlow</h4>
                    <p>
                      Get your deliverability score for all your inboxes.
                    </p>
                  </div>
                </div>
              </div>
            </section>            <section
              className="elementor-section elementor-top-section elementor-element elementor-element-86b8f89 bg-primary-alt elementor-section-boxed elementor-section-height-default elementor-section-height-default"
              data-id="86b8f89"
              data-element_type="section"
              data-settings='{"shape_divider_bottom":"book"}'
            >
              <div className="container elementor-column-gap-default">
                <div className="row">
                  <div
                    className="col-12 elementor-top-column elementor-element elementor-element-afd53ec"
                    data-id="afd53ec"
                    data-element_type="column"
                  >
                    <div className="elementor-column-wrap elementor-element-populated">
                      <div className="elementor-widget-wrap">
                        <div
                          className="elementor-element elementor-element-f854303 elementor-widget elementor-widget-text-editor"
                          data-id="f854303"
                          data-element_type="widget"
                          data-widget_type="text-editor.default"
                        >
                          <div className="elementor-widget-container">
                            <div className="elementor-text-editor elementor-clearfix">
                              <h2>
                                For Everything Else, You Can Rely On Our
                                Powerful{' '}
                                <span style={{ color: '#ff6600' }}>Zapier</span>{' '}
                                Integration.
                              </h2>
                              <p>
                                <a
                                  href="https://zapier.com/apps/quickmail/integrations?utm_medium=partner_api&utm_source=widget&utm_campaign=Widget"
                                  target="_blank"
                                  rel="noopener"
                                ></a>
                              </p>
                              <div>
                                <zapier-app-directory
                                  app="quickmail"
                                  theme="light"
                                  applimit={8}
                                  zaplimit={4}
                                  introcopy="hide"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      <div className="d-none diagonal-group light-gray-bg white-diagonal-before light-gray-diagonal-after mt-5 plain-page">
        <div className="container padded"></div>
      </div>
    </>
  )
}

export default Integrations
